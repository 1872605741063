import React from 'react'
import './HomeMission.css'

const HomeMission = () => {
  return (
    <>
      <div className="mission-home">
        <div className="mission-home-content container">
          <h1>Our Mission</h1>
          <p>
          At Recovery & Wellness Solutions, we are dedicated to empowering the substance abuse and mental health treatment industry through innovative, compassionate, and comprehensive mental consulting solutions. Our mission is to enhance the well-being and recovery of individuals by providing exceptional support, evidence-based practices, and personalized care. We strive to be a beacon of hope and a catalyst for positive change, fostering resilience and promoting sustainable recovery within the communities we serve.
          </p>

        </div>
      </div>
    </>
  )
}

export default HomeMission
